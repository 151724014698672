import HomeSearch from '@/components2/HomeSearch';
import Features from '@/components2/Features';
import { CDN2 } from '@helpers/api';
import { useMobxStores } from '@stores/index';
import { GetServerSideProps } from 'next';
import dynamic from 'next/dynamic';
import Head from 'next/head';
import { useEffect, useState, Suspense } from 'react';
import { airportCarousel } from '@/utils/airportCarouselContent';
import AirportCarouselHome from '@/components2/AirportCarouselHome';
import Image from 'next/image';
import { basicPageProps, mobileProps } from '@/utils/isServer';
import ModalDownloadApp from '@/components2/ModalDownloadApp';
import HomeSearchBC from '@/components/HomeSearchBC';

const Airports = dynamic(() => import('@/components2/Airports'), {
  ssr: true,
});

const Guides = dynamic(() => import('@/components2/Guides'), {
  ssr: true,
});

const Monthlies = dynamic(() => import('@/components2/Monthlies'), {
  ssr: true,
});

export const getServerSideProps: GetServerSideProps = async (ctx) => {
  const { isMobile, isIos } = mobileProps(ctx);
  const { airports } = await basicPageProps(ctx);

  return {
    props: {
      airports,
      deviceType: isMobile ? 'mobile' : 'desktop',
      mobileType: isIos ? 'ios' : 'android',
    },
  };
};

function Home({ airports, deviceType, mobileType }) {
  const { authStore, searchStore } = useMobxStores();
  const [loggedIn, setLoggedIn] = useState(false);
  const { uiStore } = useMobxStores();

  useEffect(() => {
    setLoggedIn(authStore.signedIn);
  }, [authStore, authStore.signedIn]);

  useEffect(() => {
    searchStore.clearAirportAndCityStorages();
  }, [searchStore]);

  return (
    <div className="bg-white">
      <Head>
        <meta
          name="description"
          content="Find your local airport and reserve the cheapest airport parking through On Air Parking. We guarantee the lowest rates with the most professional facilities. Free shuttles and cancellations are always included. Book now!"
        />
        <title>
          Find your local airport and reserve the cheapest airport parking
          through On Air Parking. We guarantee the lowest rates with the most
          professional facilities. Free shuttles and cancellations are always
          included. Book now!
        </title>
      </Head>
      <HomeSearchBC airports={airports} deviceType={deviceType} />

      <div className="mx-auto px-auto bg-white  airports-slider">
        <div className={`${'bg-whyOnAir'}`}>
          <Features />
        </div>

        {deviceType === 'desktop' && (
          <Suspense>
            <AirportCarouselHome items={airportCarousel} />
          </Suspense>
        )}
      </div>
      <Suspense>
        {/* {deviceType === 'mobile' ? (
          <ModalDownloadApp deviceType={'mobile'} mobileType={mobileType} />
        ) : (
          <ModalDownloadApp />
        )} */}
        <Airports airports={airports} />
        <Guides airports={airports} />
        <Monthlies airports={airports} />
      </Suspense>
    </div>
  );
}
Home.layout = 'DefaultLayout2';
export default Home;
