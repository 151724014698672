import { Button } from 'antd';
import React from 'react';
import { useMobxStores } from '@/stores';
import { faCar, faPlane, faShip } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default function ButtonSearchBC({ ...props }) {
  const { uiStore } = useMobxStores();

  return (
    <div className={`flex justify-center mx-auto gap-2 `}>
      <Button
        type="text"
        onClick={() => uiStore.setSearchSwitchTerm('airport')}
        size="large"
        className={`h-20 w-20 md:h-16 md:w-16 flex flex-col justify-center items-center text-base md:text-sm space-y-1
          ${
            uiStore.searchSwitchTerm === 'airport'
              ? `text-primary font-bold border-2 border-primary bg-white rounded-lg`
              : `text-onair-gray border border-gray-300 md:hover:text-primary md:hover:font-bold bg-gray-100 rounded-lg`
          }`}
      >
        <FontAwesomeIcon
          icon={faPlane}
          {...props}
          className="rotate-[315deg]"
          style={{ width: 20, height: 20 }}
        />
        Airport
      </Button>

      <Button
        onClick={() => uiStore.setSearchSwitchTerm('city')}
        onMouseDown={() => console.log('down')}
        type="text"
        size="large"
        className={`h-20 w-20 md:h-16 md:w-16 flex flex-col justify-center items-center text-base md:text-sm space-y-1
          ${
            uiStore.searchSwitchTerm === 'city'
              ? `text-primary font-bold border-2 border-primary bg-white rounded-lg`
              : `text-onair-gray border border-gray-300 md:hover:text-primary md:hover:font-bold bg-gray-100 rounded-lg`
          }`}
      >
        <FontAwesomeIcon
          icon={faCar}
          {...props}
          style={{ width: 20, height: 20 }}
        />
        City
      </Button>

      <Button
        onClick={() => uiStore.setSearchSwitchTerm('cruise')}
        onMouseDown={() => console.log('down')}
        type="text"
        size="large"
        className={`h-20 w-20 md:h-16 md:w-16 flex flex-col justify-center items-center text-base md:text-sm space-y-1
          ${
            uiStore.searchSwitchTerm === 'cruise'
              ? `text-primary font-bold border-2 border-primary bg-white rounded-lg`
              : `text-onair-gray  border border-gray-300 md:hover:text-primary md:hover:font-bold bg-gray-100 rounded-lg`
          }`}
      >
        <FontAwesomeIcon
          icon={faShip}
          {...props}
          style={{ width: 20, height: 20 }}
        />
        Cruise
      </Button>
    </div>
  );
}
