import React from 'react';
import { Carousel, Tooltip } from 'antd';
import { CheckOutlined, DollarCircleFilled } from '@/components2/Icons';
import { CDN2 } from '@/helpers/api';
import Image from 'next/image';

export default function Features() {
  return (
    <div className="flex flex-col justify-center w-full !px-0 mx-auto">
      <div className={`flex flex-col md:my-4 mx-auto w-[65%] `}>
        <h2 className="text-primary italic text-center text-3xl md:text-4xl font-bold mt-8 md:mt-4 mb-6">
          Why choose On Air Parking?
        </h2>
        <div
          className={
            'flex flex-col md:flex-row w-full h-full gap-x-4 mb-4 justify-between md:pl-12'
          }
        >
          <div className="flex flex-col items-center md:items-start w-full">
            <h3 className="text-primary text-base md:text-xl">
              Save big with{' '}
              <DollarCircleFilled className="text-primary text-2xl" /> Cheap
              Rates:
            </h3>
            <p className="mt-2 text-sm text-center md:text-left  md:text-base mb-3">
              5 star parking facilities for the cheapest rates guaranteed.
            </p>
            <ul className="hidden md:flex md:flex-row md:flex-wrap md:justify-center xl:justify-between items-center gap-4">
              <li className="min-h-20">
                <Image
                  quality={100}
                  src={CDN2() + '/staticmyapp/npa-min2.png'}
                  alt="National Parking Association"
                  width={120}
                  height={50}
                />
              </li>
              <li>
                <Image
                  quality={100}
                  src={CDN2() + '/staticmyapp/twp-logo2.png'}
                  alt="The Washington Post"
                  width={155}
                  height={60}
                />
              </li>
              <li>
                <Image
                  quality={100}
                  src={CDN2() + '/staticmyapp/forbes.png'}
                  alt="Forbes"
                  width={92}
                  height={25}
                />
              </li>
              <li>
                <Image
                  quality={100}
                  src={CDN2() + '/staticmyapp/inc.png'}
                  alt="Inc"
                  width={75}
                  height={25}
                />
              </li>
            </ul>
          </div>

          <div className="md:hidden h-16 grid grid-cols-1 justify-center items-center">
            <Carousel
              autoplay
              effect="scrollx"
              infinite
              easing="ease"
              dots={false}
              vertical={false}
              adaptiveHeight={true}
              useCSS
              useTransform
              lazyLoad="progressive"
              className="overflow-hidden md:overflow-visible mt-2 text-center justify-center items-center"
            >
              <div className="w-52 h-11">
                <Image
                  quality={100}
                  src={CDN2() + '/staticmyapp/npa-min.png'}
                  alt="National Parking Association"
                  width={0}
                  height={0}
                  sizes="100vw"
                  className="mx-auto w-auto h-full"
                />
              </div>
              <div className="w-52 h-11">
                <Image
                  quality={100}
                  src={CDN2() + '/staticmyapp/theWashington-svg.svg'}
                  alt="The Washington Post"
                  width={0}
                  height={0}
                  sizes="100vw"
                  className="mx-auto w-auto h-full"
                />
              </div>
              <div className="w-52 h-11">
                <Image
                  quality={100}
                  src={CDN2() + '/staticmyapp/forbes.png'}
                  alt="Forbes"
                  width={0}
                  height={0}
                  sizes="100vw"
                  className="mx-auto w-auto h-full"
                />
              </div>
              <div className="w-52 h-11">
                <Image
                  quality={100}
                  src={CDN2() + '/staticmyapp/inc.png'}
                  alt="Inc."
                  width={0}
                  height={0}
                  sizes="100vw"
                  className="mx-auto w-auto h-full"
                />
              </div>
            </Carousel>
          </div>

          <div className="flex flex-col w-full md:w-[60%] justify-evenly items-center gap-y-2 md:-gap-y-0 mt-4 md:-mt-2 pt-2 md:ml-4">
            <p className="text-center text-sm md:text-base font-bold text-primary self-center md:self-start">
              <CheckOutlined /> Over $50 million in savings
            </p>
            <p className="text-center text-sm md:text-base font-bold text-primary self-center md:self-start">
              <CheckOutlined /> 24/7 parking options
            </p>
            <p className="text-center text-sm md:text-base font-bold text-primary self-center md:self-start">
              <CheckOutlined /> Low price guarantee
            </p>
          </div>

          <div className="hidden md:flex text-center self-center mt-10 md:mt-0">
            <Tooltip title="Since we started we've planted 29,058 trees in partnership with The National Forest Foundation. Also, a big thanks to our parkers that share shuttles for reducing carbon emissions!">
              <Image
                quality={100}
                src={CDN2() + '/staticmyapp/sustainable.png'}
                alt="Inc."
                width={50}
                height={50}
              />
            </Tooltip>
          </div>
          <div className="flex md:hidden text-center self-center mt-10 md:mt-0">
            <Tooltip title="Since we started we've planted 29,058 trees in partnership with The National Forest Foundation. Also, a big thanks to our parkers that share shuttles for reducing carbon emissions!">
              <Image
                quality={100}
                src={CDN2() + '/staticmyapp/sustainable.png'}
                alt="Inc."
                width={40}
                height={40}
              />
            </Tooltip>
          </div>
        </div>
      </div>
    </div>
  );
}
